import React from 'react';
import MainLayout from '../layouts/main';
import { Button, Typography } from '@material-ui/core';
import styled from '../styled';
import PageHead from '../components/PageHead';
import { SEO } from '../components/SEO';
import { DownloadCloud } from 'react-feather';
import { COLORS } from '../themes/color';
import { Label } from '../components/Section';

const A = styled('a')`
  color: ${(p) => p.theme.palette.primary.main};
  border-bottom: 1px solid;
`;

const HexWrapper = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: ${(p) => p.theme.spacing(3)}px;
  grid-row-gap: ${(p) => p.theme.spacing(3)}px;
  align-items: center;
  justify-content: center;

  @media (max-width: 600px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const LogosWrapper = styled('div')`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-column-gap: ${(p) => p.theme.spacing(3)}px;
  grid-row-gap: ${(p) => p.theme.spacing(3)}px;
  align-items: center;
  justify-content: center;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

const LogoContainer = styled('div')`
  position: relative;
  display: grid;
  align-items: center;
  justify-content: center;
  border: 1px solid #f5f4f4;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.03);
  border-radius: ${(p) => p.theme.shape.borderRadius}px;
  padding: ${(p) => p.theme.spacing(8)}px ${(p) => p.theme.spacing(4)}px;
`;

const Integrations = styled('div')`
  text-align: center;
  width: 100%;
  margin: 0 auto;
  color: #333;
  max-width: 1000px;
  width: 80%;
  padding: 24px;
`;

const Headline = styled(Typography)`
  max-width: 800px;
  margin: 0 auto ${(p) => p.theme.spacing(3)}px;
  width: 90%;

  @media (max-width: 1200px) {
    font-size: ${(p) => p.theme.typography.h5.fontSize}px;
    line-height: ${(p) => p.theme.typography.h5.lineHeight};
    margin: 0 auto ${(p) => p.theme.spacing(2)}px;
  }
`;

const HexCodeWrapper = styled('div')`
  display: block;
  border-radius: ${(p) => p.theme.shape.borderRadius}px;
  border: 1px solid #eee;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.03);
`;

const HexCodeColor = styled('div')`
  height: 100px;
  border-top-right-radius: ${(p) => p.theme.shape.borderRadius}px;
  border-top-left-radius: ${(p) => p.theme.shape.borderRadius}px;
  border-bottom: 1px solid #eee;
`;

const HexCodeInner = styled('div')`
  padding: ${(p) => p.theme.spacing(2)}px;
`;

const HexCode = ({ color, name }: { name: string; color: string }) => {
  return (
    <HexCodeWrapper>
      <HexCodeColor style={{ backgroundColor: color }} />
      <HexCodeInner>
        <Typography variant="body1" component="p" paragraph>
          {name}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {color}
        </Typography>
      </HexCodeInner>
    </HexCodeWrapper>
  );
};

export default () => (
  <MainLayout>
    <div>
      <SEO
        pathname="/brand/"
        title="Affilimate Brand Assets"
        description="Downloads and guidelines for representing the Affilimate brand on the web."
      />
      <PageHead style={{ marginBottom: '24px' }}>
        <Headline variant="h4" component="h1" style={{ fontWeight: 900 }}>
          Brand Assets
        </Headline>
        <Typography
          paragraph
          color="textSecondary"
          style={{ maxWidth: '600px', padding: '24px', margin: '0 auto' }}
        >
          Thanks for your interest in Affilimate! We've a few guidelines for
          using our brand assets. Please take a second to familiarize yourself
          with them before use.
        </Typography>
        <Button
          color="primary"
          variant="contained"
          size="large"
          href="https://storage.googleapis.com/affilimate-assets/assets.zip"
        >
          <DownloadCloud size={18} /> &nbsp; Download Brand Assets
        </Button>
      </PageHead>
      <Integrations style={{ marginBottom: '24px' }}>
        <Typography paragraph variant="h6" component="h2">
          The Affilimate Logo
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Our logo is only meant to be used in black, white, or grayscale
          variations.
        </Typography>
        <div style={{ marginBottom: '48px', marginTop: '48px' }}>
          <LogosWrapper>
            <LogoContainer>
              <img
                src="https://storage.googleapis.com/affilimate-assets/logos/logo-black-full.png"
                style={{ maxHeight: '50px', margin: '12px auto 48px' }}
              />
              <Label>Logo</Label>
              <Typography variant="body2" color="textSecondary">
                For use in most circumstances where you need to represent our
                product and company.
                <br />
                <br />
                <A href="https://storage.googleapis.com/affilimate-assets/logos/logo-black-full.png">
                  PNG Black
                </A>{' '}
                /{' '}
                <A href="https://storage.googleapis.com/affilimate-assets/logos/logo-white-full.png">
                  PNG White
                </A>
              </Typography>
            </LogoContainer>
            <LogoContainer>
              <img
                src="https://storage.googleapis.com/affilimate-assets/logos/icon-black-circle.png"
                style={{ maxHeight: '50px', margin: '12px auto 48px' }}
              />
              <Label>Icon</Label>
              <Typography variant="body2" color="textSecondary">
                In case you need an icon that fits in square dimensions, such as
                social media platforms.
                <br />
                <br />
                <A href="https://storage.googleapis.com/affilimate-assets/logos/icon-black-circle.png">
                  PNG Black
                </A>{' '}
                /{' '}
                <A href="https://storage.googleapis.com/affilimate-assets/logos/icon-white-circle.png">
                  PNG White
                </A>
              </Typography>
            </LogoContainer>
          </LogosWrapper>
        </div>
      </Integrations>
      <Integrations style={{ marginBottom: '24px' }}>
        <Typography paragraph variant="h6" component="h2">
          Affilimate Brand Colors
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Our primary color is Brand Blue (#1890ff) and secondary color is Brand
          Wine (#250522).
        </Typography>
        <div style={{ marginBottom: '48px', marginTop: '48px' }}>
          <HexWrapper>
            <HexCode color="#1890ff" name="Blue" />
            <HexCode color="#250522" name="Wine" />
            <HexCode color={COLORS.PAPER_DARK} name="Paper" />
            <HexCode color="#f759ab" name="Magenta" />
            <HexCode color="#bae7ff" name="Light Blue" />
            <HexCode color="#bda0b9" name="Light Wine" />
            <HexCode color={COLORS.PAPER} name="Light Paper" />
            <HexCode color="#ffd6e7" name="Light Magenta" />
          </HexWrapper>
        </div>
      </Integrations>
      <Integrations style={{ marginBottom: '24px' }}>
        <Typography paragraph variant="h6" component="h2">
          Product Illustrations
        </Typography>
        <Typography variant="body2" color="textSecondary">
          When writing about our product, you're welcome to use these images.
        </Typography>
        <div style={{ marginBottom: '48px', marginTop: '24px' }}>
          <LogosWrapper>
            <LogoContainer>
              <img
                src="/images/features/new-transaction-history.png"
                style={{
                  maxHeight: '250px',
                  maxWidth: '100%',
                  borderRadius: '8px',
                  margin: '12px auto 48px'
                }}
              />
              <Label>Dashboard</Label>
              <Typography variant="body2" color="textSecondary">
                Showing our unified affiliate dashboard.
                <br />
                <br />
                <A href="/images/features/new-transaction-history.png">PNG</A>
              </Typography>
            </LogoContainer>
            <LogoContainer>
              <img
                src="/images/features/new-heatmaps.png"
                style={{
                  maxHeight: '250px',
                  maxWidth: '100%',
                  borderRadius: '8px',
                  margin: '12px auto 48px'
                }}
              />
              <Label>Heatmaps</Label>
              <Typography variant="body2" color="textSecondary">
                Showing our heatmaps visualization.
                <br />
                <br />
                <A href="/images/features/new-heatmaps.png">PNG</A>
              </Typography>
            </LogoContainer>
            <LogoContainer>
              <img
                src="/images/features/new-page-level-reporting.png"
                style={{
                  maxHeight: '250px',
                  maxWidth: '100%',
                  borderRadius: '8px',
                  margin: '12px auto 48px'
                }}
              />
              <Label>Content Analytics</Label>
              <Typography variant="body2" color="textSecondary">
                Showing our Content Analytics overview.
                <br />
                <br />
                <A href="/images/features/new-page-level-reporting.png">PNG</A>
              </Typography>
            </LogoContainer>
            <LogoContainer>
              <img
                src="/images/features/new-revisions.png"
                style={{
                  maxHeight: '250px',
                  maxWidth: '100%',
                  borderRadius: '8px',
                  margin: '12px auto 48px'
                }}
              />
              <Label>Revisions</Label>
              <Typography variant="body2" color="textSecondary">
                Showing our revision history.
                <br />
                <br />
                <A href="/images/features/new-revisions.png">PNG</A>
              </Typography>
            </LogoContainer>
            <LogoContainer>
              <img
                src="/images/features/new-segmentation.png"
                style={{
                  maxHeight: '250px',
                  maxWidth: '100%',
                  borderRadius: '8px',
                  margin: '12px auto 48px'
                }}
              />
              <Label>Segmentation</Label>
              <Typography variant="body2" color="textSecondary">
                Showing our content segmentation.
                <br />
                <br />
                <A href="/images/features/new-segmentation.png">PNG</A>
              </Typography>
            </LogoContainer>
          </LogosWrapper>
        </div>
      </Integrations>
    </div>
  </MainLayout>
);
